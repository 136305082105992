var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-slide-x-transition',[(_vm.items.length && !_vm.isLoading)?_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item.uuid,attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"preview",attrs:{"cols":"5"}},[_c('div',[(item.product_details)?_c('div',{staticClass:"preview"},[(item.product_details.path_img_thumb)?_c('img',{attrs:{"src":item.product_details.path_img_thumb,"alt":""}}):_vm._e(),(!item.product_details.path_img_thumb && item.product_details.path_img)?_c('img',{attrs:{"src":item.product_details.path_img,"alt":""}}):_vm._e(),(!item.product_details.path_img && !item.product_details.path_img_thumb)?_c('img',{staticClass:"img-thumb",attrs:{"src":require('@/assets/images/product-desc.png')}}):_vm._e()]):_vm._e(),(!item.product_details)?_c('div',[(item.icon)?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(`mdi-${item.icon}`))]):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-table-merge-cells")])],1):_vm._e()])]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-simple-table',{staticClass:"my-table supply",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("table.name"))+":")]),_c('td',{class:{
                          copyTxt: item.product_details ? item.product_details.name : item.name
                        },on:{"click":function($event){return _vm.copyToClipboard(
                            item.product_details ? item.product_details.name : item.name
                          )}}},[_vm._v(" "+_vm._s(item.product_details ? item.product_details.name : item.name || "--")+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("table.description"))+":")]),_c('td',{staticClass:"text-truncate",class:{
                          copyTxt: item.product_details
                            ? item.product_details.description
                            : item.description
                        },on:{"click":function($event){return _vm.copyToClipboard(
                            item.product_details
                              ? item.product_details.description
                              : item.description
                          )}}},[_vm._v(" "+_vm._s(item.product_details ? item.product_details.description : item.description || "--")+" ")])]),(item.product_details)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("table.bar_code"))+"::")]),_c('td',{staticClass:"copyTxt",on:{"click":function($event){return _vm.copyToClipboard(item.product_details.bar_code)}}},[_vm._v(" "+_vm._s(item.product_details.bar_code || "--")+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("table.ns_code"))+"::")]),_c('td',{class:{
                          copyTxt: item.ns_code
                        },on:{"click":function($event){return _vm.copyToClipboard(item.ns_code)}}},[_vm._v(" "+_vm._s(item.ns_code || "--")+" ")])]),(item.product_details)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("table.amount"))+"::")]),_c('td',[_vm._v(_vm._s(item.count || 0))])]):_vm._e()])])],1)],1)],1)],1)],1)}),1):_vm._e(),(!_vm.items.length && _vm.chosenElements)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"6"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-text',[_vm._v(_vm._s(_vm.$t('table.noData')))])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }