import { render, staticRenderFns } from "./NestedComponent.vue?vue&type=template&id=54a5055f&"
import script from "./NestedComponent.vue?vue&type=script&lang=js&"
export * from "./NestedComponent.vue?vue&type=script&lang=js&"
import style0 from "./NestedComponent.vue?vue&type=style&index=0&id=54a5055f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAppBarNavIcon,VAutocomplete,VCard,VCardTitle,VCol,VDivider,VNavigationDrawer,VRow,VTextField})
