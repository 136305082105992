<template>
  <div>
    <v-data-table
      :items="items"
      :headers="headers"
      dense
      :items-per-page="items.length"
      hide-default-footer
      :no-data-text="$t('table.noData')"
      class="my-table supply"
      :loading="isLoading"
      v-if="chosenElements"
    >
      <template v-slot:item.photo="{ item }">
        <div v-if="item.product_details" class="text-center">
          <img
            v-if="!item.product_details.path_img_thumb && !item.product_details.path_img"
            :src="require('@/assets/images/supply-item.png')"
            class="image-placeholder expanded-image sm"
          />
          <v-img
            v-else
            :src="item.product_details.path_img_thumb"
            class="image expanded-image sm"
            @click="showImgDialog(item.product_details.path_img)"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                style="min-height:60px"
              >
                <v-progress-circular
                  indeterminate
                  :size="10"
                  :width="1"
                  color="green lighten-1"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <div v-else class="text-center">
          <v-icon v-if="item.icon">{{ `mdi-${item.icon}` }}</v-icon>
          <v-icon v-else>mdi-table-merge-cells</v-icon>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div
          :class="{
            copyTxt: item.product_details ? item.product_details.name : item.name
          }"
          @click="copyToClipboard(item.product_details ? item.product_details.name : item.name)"
        >
          {{ item.product_details ? item.product_details.name : item.name || "--" }}
        </div>
      </template>
      <template v-slot:item.description="{ item }">
        <div
          :class="{
            copyTxt: item.product_details ? item.product_details.description : item.description
          }"
          @click="
            copyToClipboard(
              item.product_details ? item.product_details.description : item.description
            )
          "
        >
          {{
            item.product_details
              ? item.product_details.description || "--"
              : item.description || "--"
          }}
        </div>
      </template>
      <template v-slot:item.bar_code="{ item }">
        <div
          v-if="item.product_details"
          :class="{
            copyTxt: item.product_details.bar_code
          }"
          @click="copyToClipboard(item.product_details.bar_code)"
        >
          {{ item.product_details.bar_code || "--" }}
        </div>
        <div v-else>
          {{ "--" }}
        </div>
      </template>
      <template v-slot:item.ns_code="{ item }">
        <div
          :class="{
            copyTxt: item.ns_code
          }"
          @click="copyToClipboard(item.ns_code)"
        >
          {{ item.ns_code || "--" }}
        </div>
      </template>
      <template v-slot:item.count="{ item }">
        <div v-if="item.product_details" class="text-center">
          {{ item.count || 0 }}
        </div>
        <div v-else class="text-center">
          {{ 1 }}
        </div>
      </template>
    </v-data-table>
    <img-dialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </div>
</template>

<script>
import EventBus from "../../../events/EventBus";
import imgDialog from "../../products/imgDialog.vue";
import { mapGetters } from "vuex";

export default {
  components: { imgDialog },
  mounted() {
      this.headers = [
        {
          text: this.$t("table.photo"),
          value: "photo",
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("table.name"),
          value: "name"
        },
        {
          text: this.$t("table.description"),
          value: "description",
          sortable: false
        },
        {
          text: this.$t("table.bar_code"),
          value: "bar_code",
          sortable: false
        },
        {
          text: this.$t("table.ns_code"),
          value: "ns_code",
          sortable: false
        },
        {
          text: this.$t("table.amount"),
          value: "count",
          align: "center"
        }
      ];
  },
  methods: {
    renderHeaders() {
      this.headers = [
        {
          text: this.$t("table.photo"),
          value: "photo",
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("table.name"),
          value: "name"
        },
        {
          text: this.$t("table.description"),
          value: "description",
          sortable: false
        },
        {
          text: this.$t("table.bar_code"),
          value: "bar_code",
          sortable: false
        },
        {
          text: this.$t("table.ns_code"),
          value: "ns_code",
          sortable: false
        },
        {
          text: this.$t("table.amount"),
          value: "count",
          align: "center"
        }
      ];
    },
    showImgDialog(image) {
      this.imgpath = image;
      this.imgDialog = true;
    },
    copyToClipboard(data) {
      if (!data) return;
      EventBus.$emit("on-clipboard", data);
    }
  },
  computed: {
    ...mapGetters(["languages", "currentLanguage"])
  },
  data: () => ({
    imgpath: "",
    imgDialog: false,
    headers: []
  }),
  watch: {
    currentLanguage() {
      this.renderHeaders();
    },
    items:{
      deep:true,
      handler(){
         this.renderHeaders();
      }

    }
  },
  props: {
    items: { required: true },
    chosenElements: { required: true },
    isLoading: { required: true }
  }
};
</script>

<style></style>
