var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.chosenElements)?_c('v-data-table',{staticClass:"my-table supply",attrs:{"items":_vm.items,"headers":_vm.headers,"dense":"","items-per-page":_vm.items.length,"hide-default-footer":"","no-data-text":_vm.$t('table.noData'),"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.photo",fn:function({ item }){return [(item.product_details)?_c('div',{staticClass:"text-center"},[(!item.product_details.path_img_thumb && !item.product_details.path_img)?_c('img',{staticClass:"image-placeholder expanded-image sm",attrs:{"src":require('@/assets/images/supply-item.png')}}):_c('v-img',{staticClass:"image expanded-image sm",attrs:{"src":item.product_details.path_img_thumb},on:{"click":function($event){return _vm.showImgDialog(item.product_details.path_img)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",staticStyle:{"min-height":"60px"},attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":10,"width":1,"color":"green lighten-1"}})],1)]},proxy:true}],null,true)})],1):_c('div',{staticClass:"text-center"},[(item.icon)?_c('v-icon',[_vm._v(_vm._s(`mdi-${item.icon}`))]):_c('v-icon',[_vm._v("mdi-table-merge-cells")])],1)]}},{key:"item.name",fn:function({ item }){return [_c('div',{class:{
            copyTxt: item.product_details ? item.product_details.name : item.name
          },on:{"click":function($event){return _vm.copyToClipboard(item.product_details ? item.product_details.name : item.name)}}},[_vm._v(" "+_vm._s(item.product_details ? item.product_details.name : item.name || "--")+" ")])]}},{key:"item.description",fn:function({ item }){return [_c('div',{class:{
            copyTxt: item.product_details ? item.product_details.description : item.description
          },on:{"click":function($event){return _vm.copyToClipboard(
              item.product_details ? item.product_details.description : item.description
            )}}},[_vm._v(" "+_vm._s(item.product_details ? item.product_details.description || "--" : item.description || "--")+" ")])]}},{key:"item.bar_code",fn:function({ item }){return [(item.product_details)?_c('div',{class:{
            copyTxt: item.product_details.bar_code
          },on:{"click":function($event){return _vm.copyToClipboard(item.product_details.bar_code)}}},[_vm._v(" "+_vm._s(item.product_details.bar_code || "--")+" ")]):_c('div',[_vm._v(" "+_vm._s("--")+" ")])]}},{key:"item.ns_code",fn:function({ item }){return [_c('div',{class:{
            copyTxt: item.ns_code
          },on:{"click":function($event){return _vm.copyToClipboard(item.ns_code)}}},[_vm._v(" "+_vm._s(item.ns_code || "--")+" ")])]}},{key:"item.count",fn:function({ item }){return [(item.product_details)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.count || 0)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(1)+" ")])]}}],null,false,1755403891)}):_vm._e(),_c('img-dialog',{attrs:{"visible":_vm.imgDialog,"imgpath":_vm.imgpath},on:{"close":function($event){_vm.imgDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }